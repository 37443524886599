import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import apiServices from "../services/apiServices";
import { useNavigate } from "react-router";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const Login = () => {
  const navigate = useNavigate("");
  const [emailData, setMailData] = useState("");
  const [passwordData, setPasswordData] = useState("");

  const deleteNotification = () => {
    NotificationManager.error("Invalid username or password", "Error", 3000);
  };

  const handleLoginForm = async (e) => {
    e.preventDefault();
    const body = {
      email: emailData,
      password: passwordData,
    };
    const res = await apiServices.loginForm(body);
    if (res) {
      navigate("/apps");
      window.location.reload();
    } else {
      deleteNotification();
    }
  };

  return (
    <div className="login-page loginBody">
      <NotificationContainer />
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={6} lg={4} xs={12}>
            <Card className="shadow login-card">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="mb-3">
                    <Form id="loginform">
                      <h2 id="headerTitle">Delight Pharma</h2>
                      <Form.Group
                        className="mb-3 row"
                        controlId="formBasicEmail"
                      >
                        <Form.Label className="loginLabel">Username</Form.Label>
                        <Form.Control
                          type="email"
                          className="loginInput"
                          placeholder="Enter username"
                          onChange={(e) => setMailData(e.target.value)}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 row"
                        controlId="formBasicPassword"
                      >
                        <Form.Label className="loginLabel">Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Password"
                          className="loginInput"
                          onChange={(e) => setPasswordData(e.target.value)}
                        />
                      </Form.Group>

                      <div className="d-grid">
                        <Button
                          type="submit"
                          className="loginButton"
                          onClick={handleLoginForm}
                        >
                          Sign In
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
